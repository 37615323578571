<template>
    <div>
        <div class="card-toolbar mb-5">
            <button v-if="$can('general_group.create')" @click="showModal" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon>
                {{ $t('general_group.new_general_group') }}
            </button>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>
        
        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_name">{{$t('general_group.name')}}</label>
                            <input v-model="filters.name" type="text" id="f_name" class="form-control">
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="type">
                                {{$t('general_group.type')}}
                            </label>
                            <select name="" id="type" v-model="filters.type" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in type_list" :value="row.id" :key="index">{{ row.title }}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('status')}}
                            </label>
                            <select name="" id="f_status" v-model="filters.is_active" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                            <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                            <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                <div>
                    <div class="d-flex justify-content-end mb-5">
                        <custom-export-data v-if="$can('general_group.export_data')" :data-list="dataList" :file-name="fileName" :fields="json_fields"></custom-export-data>
                    </div>
                </div>

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
                        <b-form-checkbox v-if="$can('general_group.change_status')" size="lg" @change="changeStatus(props.row.id, props.row.is_active)" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                        <b-form-checkbox v-else :disabled="true" size="lg" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('general_group.update')" @click="showModal(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('general_group.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->

        <b-modal ref="modal" hide-footer :title="$t('general_group.general_group')">
          <div class="row">
            <div class="col-lg-6 mb-5">
              <label for="name">{{$t('general_group.name')}}<span class="text-danger">*</span></label>
              <input v-model="item.name" type="text" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''" id="name" :placeholder="$t('general_group.name')">
              <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.name[0] }}
                </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label for="_type">{{$t('general_group.type')}}<span class="text-danger">*</span></label>
              <select name="" v-model="item.type" class="custom-select" id="_type" :class="validation && validation.type ? 'is-invalid' : ''" >
                <option v-for="row in type_list" :value="row.id" :key="row.id">{{ row.title }}</option>
              </select>
              <span v-if="validation && validation.type" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.type[0] }}
                </span>
            </div>
            <div class="col-lg-6 mb-5">
              <b-form-checkbox size="lg" v-model="item.is_active" name="check-button" switch>{{$t('status')}}</b-form-checkbox>
            </div>
            <div class="col-lg-12 mb-5">
              <label for="notes">{{$t('general_group.notes')}}</label>
              <textarea v-model="item.notes" type="text" class="form-control" id="notes"></textarea>
              <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.notes[0] }}
                </span>
            </div>
          </div>



            <div class="d-flex justify-content-end">
                <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{$t('close')}}</b-button>
                <b-button class="mt-2 mr-1" variant="primary" @click="save">{{$t('save')}}</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-general-group",
        components: {},
        data() {
            return {
                mainRoute: 'settings/general_groups',
                subMainRoute: 'settings/general_group',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,
                filters: {
                    name: null,
                    type: null,
                    is_active: null,
                },
                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
                columns: ['name', 'type_name', 'status', 'actions'],

                idEditing: null,
                isEditing: false,

                item: {
                    id: null,
                    name: null,
                    notes: null,
                    type: null,
                    is_active: true,
                },
                disabledButton: false,
                validation: [],
                type_list: [],
                dataList : [],
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('general_group.name')] = 'name';
                fields[this.$t('general_group.type')] = 'type_name';
                fields[this.$t('status')] = 'status';
                return fields;
            },
            fileName : function () {
                return this.$t('general_group.general_group');
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('general_group.name'),
                        type_name: that.$t('general_group.type'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },

        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.general_group")}]);
            this.getTypeList();
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = null;
                this.filters.type = null;
                this.filters.is_active = null;

                this.$refs.table.refresh();
            },

            getData(id) {
                ApiService.get(`${this.mainRoute}/${id}`).then((response) => {

                    this.isEditing = true;
                    this.item.id = response.data.data.id;
                    this.item.name = response.data.data.name;
                    this.item.notes = response.data.data.notes;
                    this.item.type = response.data.data.type;
                    this.item.is_active = response.data.data.is_active;
                });
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((errors) => {
                    this.$errorAlert(errors);
                })
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            showModal(data) {
                if (!data.id) {
                    this.reset();
                    this.$refs['modal'].show()
                } else {
                    this.isEditing = true;
                    this.id = data.id;
                    this.getData(data.id);
                    this.$refs['modal'].show();
                }
            },
            reset() {
                this.item= {
                    id: null,
                    name: null,
                    notes: null,
                    type: null,
                    is_active: true,
                };
                this.validation = [];
                this.isEditing = false;
            },
            hideModal() {
                this.$refs['modal'].hide();
                this.afterSave();
            },
            toggleModal() {
                this.$refs['modal'].toggle('#toggle-btn');
            },
            save() {
                if (this.isEditing) {
                    this.saveEdit();
                } else {
                    this.saveCreate();
                }
            },
            
            saveCreate() {
                // this.item.is_active = this.item.is_active?1:0;
                ApiService.post(this.mainRoute, this.item).then((response) => {
                    this.$successAlert(response.data.message);
                    this.afterSave();
                    this.hideModal();
                }).catch((error) => {
                    this.$errorAlert(error);
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },
            saveEdit() {
                // this.item.is_active = this.item.is_active?1:0;
                ApiService.put(`${this.mainRoute}/${this.id}`, this.item).then((response) => {
                    this.$successAlert(response.data.message);
                    this.afterSave();
                    this.hideModal();
                }).catch((error) => {
                    this.$errorAlert(error);
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },
            afterSave() {
                this.getFetch();
                this.item = {
                    id: null,
                    name: null,
                    notes: null,
                    type: null,
                    is_active: true,
                };
                this.idEditing = null;
                this.validation = null;

            },
            getTypeList() {
                ApiService.get(this.mainRouteDependency + "/general-group-type").then(response => {
                    this.type_list = response.data.data;
                });
            },
            changeStatus(id, status) {
                ApiService.patch(this.subMainRoute + '/change-status/' + id, {
                    is_active: (status ? 1 : 0),
                }).then(response => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },
        },
    };
</script>
